.flex-container {
  display: flex;
  align-items: center;
}

.flex-child {
  display: flex;
  flex-grow: 1;
}

.flex-child-grow {
  display: flex;
  flex-grow: 1000;
}

.loudspeaker .ctrf-question-container {
  text-align: right;
}

.loudspeaker .label {
  margin-right: 2px;
  margin-bottom: 2px;
}

.loudspeaker {
  display: flex;
  flex-direction: column;
  text-align: left;
}

.loudspeakerAnnouncement {
  padding: 10px;
  border-bottom: 1px solid rgb(225, 230, 235);
}
.loudspeakerAnnouncement:hover {
  background-color: aliceblue;
}

.loudspeakerViewAllButton {
  display: flex;
}

.loudspeakerNoAnnouncements {
  margin-top: 15px;
  margin-bottom: 15px;
}

.loudspeakerFooter {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}

.loudspeakerIcon {
  padding: 5px 10px;
}

.loudspeakerListSection__feedback-box {
  text-align: right;
}

.loudspeakerAnnouncement .ctrf-stars-container {
  margin: 0;
}

.loudspeakerAnnouncement .ctrf-question-title {
  font-size: 12px;
}

.loudspeakerAnnouncement .ctrf-stars-item svg {
  width: 15px;
}

.snippet-container {
  margin-bottom: 10px;
}

.loudspeakerLoudbot {
  text-align: center;
}

.loudspeakerLoudbot svg {
  width: 87px;
}

.loudspeakerModal .modal-body {
  padding-top: 0 !important;
}
